import React, { FunctionComponent } from 'react'
import { Box, BoxProps } from 'rebass'

export const DevToIcon: FunctionComponent<BoxProps> = (props) => {
  return (
    <Box
      sx={{
        display: 'inline-block',
      }}
      {...props}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 20V0H20H40V20V40H20H0V20Z" fill="#131415" />
        <path
          d="M8.2002 19.9203V13.8003H10.4002C12.9202 13.8003 13.8202 14.1003 14.6402 15.2403C15.0802 15.8803 15.1002 16.0203 15.1002 19.9403C15.1002 23.7003 15.0802 24.0003 14.6802 24.5203C13.7602 25.7603 13.3802 25.9003 10.6802 25.9603L8.2002 26.0403V19.9203ZM12.6402 23.4603C12.9602 23.1803 13.0002 22.7803 13.0002 19.8803C13.0002 16.7403 12.9802 16.6203 12.5602 16.3203C12.3202 16.1403 11.7202 16.0003 11.2602 16.0003H10.4002V19.9003V23.8003H11.3402C11.9202 23.8003 12.4002 23.6803 12.6402 23.4603Z"
          fill="#FEFEFE"
        />
        <path
          d="M17.0002 25.6001C16.6202 25.2201 16.6002 24.9401 16.6002 19.9401C16.6002 13.3401 16.3202 13.8001 20.1802 13.8001H22.8202L22.7602 14.8601L22.7002 15.9001L20.7602 15.9601L18.8002 16.0201V17.4001V18.8001H20.0002H21.2002V19.9001V21.0001H20.0002H18.8002V22.4001V23.7801L20.7602 23.8401L22.7002 23.9001L22.7602 24.9601L22.8202 26.0001H20.1202C17.6602 26.0001 17.3602 25.9601 17.0002 25.6001Z"
          fill="#FEFEFE"
        />
        <path
          d="M27.7403 25.7801C27.1603 25.4401 26.8803 24.6801 25.5003 19.4001C24.7603 16.6001 24.1003 14.2001 24.0603 14.0601C23.9803 13.8601 24.2803 13.8001 25.1603 13.8001C26.5803 13.8001 26.3203 13.3201 27.6003 18.3001C28.0403 20.0601 28.4603 21.6401 28.5203 21.8001C28.6003 22.0001 30.7203 14.5801 30.8003 13.8601C30.8003 13.8201 31.3403 13.8001 32.0203 13.8001C33.0803 13.8001 33.2203 13.8401 33.1203 14.1601C33.0803 14.3401 32.4203 16.7401 31.7003 19.4801C30.3603 24.5201 30.2003 25.0201 29.6403 25.6601C29.3003 26.0401 28.3003 26.1001 27.7403 25.7801Z"
          fill="#FEFEFE"
        />
      </svg>
    </Box>
  )
}
